<div>
  <div id="print-content" #printContent>
    <div class="header-controls split-flex">
      <h3><img src="/assets/images/iconEnroll.svg" /> Enroll </h3>
       <div class="print-hide filter-options">
          <wf-students-filter [grades]="grades" [classes]="classes" [filterExtraSupport]="false" (filterChangeEvent)="filterStudents($event)"></wf-students-filter>
      </div>
    </div>
    <div>
      <div class="report-section-header split-flex">
        <h5 class="results-report-header"><fa-icon [classes]="['print-hide']" [icon]="studentIcon"></fa-icon> Manage Subscriptions</h5>
        <div class="print-hide">
          <button class="btn scheduling-button" (click)="checkForConfirmation()"><fa-icon [icon]="customizeIcon"></fa-icon> Customize Diagnostic Schedule</button>
          <button id="print-button" class="btn print-summary-button print-hide" (click)="printStudents()"><fa-icon [icon]="printIcon"></fa-icon> Print Student List</button>
        </div>
      </div>
    </div>
    <div class="diagnosticproduct-management-text-button-bar print-hide">
      <div class="row justify-content-center print-hide">
        <div class="col-md-6" style="text-align: center;">
          <div *ngIf="numberOfDiagnosticProductLicenses !== -2">
            <h3 class="diagnosticproduct-number-of-licenses-block">
              <span *ngIf="numberOfDiagnosticProductLicenses === -1"><fa-icon [icon]="infinityIcon"></fa-icon></span>
              <span *ngIf="numberOfDiagnosticProductLicenses !== -1">
                {{ numberOfDiagnosticProductLicenses }}
              </span>
            </h3>
            <span class="bold">{{diagnosticProduct}} Subscriptions Remaining</span>
          </div>
        </div>
        <div class="col-md-6" style="text-align: center;">
          <span class="bold">{{fullProduct}} Subscriptions Remaining</span>
          <h3 class="diagnosticproduct-number-of-licenses-block">
            <span *ngIf="numberOfFullProductLicenses === -1"><fa-icon [icon]="infinityIcon"></fa-icon></span>
            <span *ngIf="numberOfFullProductLicenses !== -1">{{ numberOfFullProductLicenses }}</span>
          </h3>
        </div>
      </div>
    </div>
    <table class="report-table" id="studentDataCompleted">
      <thead>
        <th class="print-hide"></th>
        <th id="firstNameColumn" (click)="sortTable('firstName')">
          <div class="sortable-report-table-header">
            First Name
            <div [ngClass]=" { 'arrow-up' : !reverseSort, 'arrow-down': reverseSort }" [style.visibility]="(orderByField !== 'firstName') ? 'hidden' : 'visible'"></div>
          </div>
        </th>
        <th id="lastNameColumn" (click)="sortTable('lastName')">
          <div class="sortable-report-table-header">
            Last Name
            <div [ngClass]=" { 'arrow-up' : !reverseSort, 'arrow-down': reverseSort }" [style.visibility]="(orderByField !== 'lastName') ? 'hidden' : 'visible'"></div>
          </div>
        </th>
        <th id="gradeColumn" (click)="sortTable('grade')">
          <div class="sortable-report-table-header">
            Grade
            <div [ngClass]=" { 'arrow-up' : !reverseSort, 'arrow-down': reverseSort }" [style.visibility]="(orderByField !== 'grade') ? 'hidden' : 'visible'"></div>
          </div>
        </th>
        <th>Date Started</th>
        <th>Date Completed</th>
        <th>Type</th>
      </thead>
      <tr *ngFor="let student of filteredStudents">
        <td class="print-hide">
          <div *ngFor="let assessment of student.assessments; last as lastRow">
            <button type="button" *ngIf="showAddTestButton(student, lastRow)" (click)="assignDiagnosticSubscription(student)" class="btn btn-success subscription-button">
              <fa-icon [icon]="addDiagnosticIcon"></fa-icon>
              Add {{ diagnosticProduct }}
            </button>
            <button type="button" *ngIf="showRemoveTestButton(assessment)" (click)="removeDiagnosticSubscription(student, assessment.subscriptionID)" class="btn btn-danger subscription-button">
              <fa-icon [icon]="removeDiagnosticIcon"></fa-icon>
              Remove {{ diagnosticProduct }}
            </button>
            <button type="button" *ngIf="showAddFullProductButton(assessment, lastRow)" (click)="assignFullProductSubscription(student)" class="btn btn-success subscription-button">
              <fa-icon [icon]="addDiagnosticIcon"></fa-icon>
              Add {{ fullProduct }}
            </button>
            <button type="button" *ngIf="showRemoveFullProductButton(student, assessment)" (click)="removeFullProductSubscription(student, assessment.subscriptionID)" class="btn btn-danger subscription-button">
              <fa-icon [icon]="removeDiagnosticIcon"></fa-icon>
              Remove {{ fullProduct }}
            </button>
            <div *ngIf="!showAddTestButton(student, lastRow) && !showAddFullProductButton(assessment, lastRow) && !showRemoveTestButton(assessment) && !showRemoveFullProductButton(student, assessment)" class="assign-diagnostic-sub-cell"></div>
          </div>
        </td>
        <td>
          <div class="assign-diagnostic-sub-cell">{{ student.firstName }}</div>
        </td>
        <td>
          <div class="assign-diagnostic-sub-cell">{{ student.lastName }}</div>
        </td>
        <td>
          <div class="assign-diagnostic-sub-cell">{{ student.grade | displayGrade }}</div>
        </td>
        <td>
          <div *ngFor="let assessment of student.assessments">
            <div class="assign-diagnostic-sub-cell" *ngIf="assessment.dateStarted == null && !assessment.skipped">Not Started</div>
            <div class="assign-diagnostic-sub-cell" *ngIf="assessment.dateStarted != null && !assessment.skipped">{{ assessment.formattedDateStarted }}</div>
            <div class="assign-diagnostic-sub-cell" *ngIf="assessment.dateStarted != null && assessment.skipped">Skipped (Started on {{ assessment.formattedDateStarted }})</div>
            <div class="assign-diagnostic-sub-cell" *ngIf="assessment.dateStarted == null && assessment.skipped">Skipped</div>
          </div>
        </td>
       <td>
          <div *ngFor="let assessment of student.assessments">
            <div class="assign-diagnostic-sub-cell">{{assessment.formattedDateCompleted}}</div>
          </div>
        </td>
        <td>
          <div *ngFor="let assessment of student.assessments">
            <div class="assign-diagnostic-sub-cell">{{assessment.getAssessmentType()}}</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
<!-- Confirmation Dialog for Customizing Diagnostic Schedules -->
<div class="assign-confirm-dialog-background" *ngIf="showConfirmationDialog"></div>
<div class="modal-dialog" role="document" style="z-index: 1001;" *ngIf="showConfirmationDialog">
    <div class="modal-content">
      <div class="modal-body">
        <label class="assign-confirm-text">
          Instruction includes a default schedule for the Pre, Mid, and Post Diagnostics that coincides with curricular progress.
          <br><br>
          Are you sure you want to change this schedule?
        </label>
      </div>
      <div class="confirm-dialog-bottom-container modal-footer">
        <button class="btn yes-dialog-btn" (click)="goToScheduleView()">
          <span class='yes-text'>Yes</span>
        </button>
        <button class="btn no-dialog-btn" (click)="toggleConfirmation()">
          <span class='no-text'>No</span>
        </button>
      </div>
    </div>
</div>
