import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';

import { switchMap } from 'rxjs';

import * as _ from "underscore";

import * as Sentry from '@sentry/angular';

import { faChartPie, faCheckCircle, faCircleExclamation, faInfinity, faPrint, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

import { BaseChartDirective } from 'ng2-charts';

import { Student } from '../../core/models/student.model';
import { SchoolClass } from '../../core/models/school-class.model';
import { SubscriptionTypes } from '../../core/models/subscription-types.model';
import { StudentsService } from '../../core/services/students.service';
import { UtilityService } from '../../core/services/utility.service';
import { EnrollmentService } from '../../core/services/enrollment.service';
import { ManagementUser } from '../../core/models/management-user.model';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { ReportsService } from '../../core/services/reports.service';
import { StudentsFilterComponent } from '../../shared/components/students-filter/students-filter.component';
import { PrintService } from 'src/app/core/services/print.service';
import { District } from '../../core/models/district.model';
import { School } from '../../core/models/school.model';
import { ScoreCutoffs } from '../../core/models/score-cutoffs.model';

@Component({
  selector: 'wf-screener',
  templateUrl: './screener.component.html',
  styleUrls: [ './screener.component.css' ]
})
export class ScreenerComponent implements OnInit {
  currentUser: ManagementUser | null = null ;
  showSchoolFilter: boolean = false ;
  schoolFilterError: string = '' ;
  tierLabels: { [key: string]: string } = {} ;
  districts: District[] = [] ;
  schools: School[] = [] ;
  teachers: ManagementUser[] = [] ;
  isTeacher: boolean = false ;
  selectedStudents: Set<number> = new Set<number>();
  studentsAreSelected: boolean = false ;
  students: Student[] = [] ;
  filteredStudents: Student[] =[] ;
  grades: string[] = [] ;
  classes: SchoolClass[] = [] ;
  studentsNotStarted: number = 0 ;
  highRiskCnt: number = 0 ;
  someRiskCnt: number = 0 ;
  proficientCnt: number = 0 ;
  chartType: ChartType = 'pie' ;
  chartData: ChartData<'pie', number[], string | string[]> = {
    labels: [ 'Proficient', 'Some Risk', 'High Risk' ],
    datasets: [{
      data: [ this.proficientCnt, this.someRiskCnt, this.highRiskCnt ],
      backgroundColor: [
        '#58B957FF',
        '#F2AE43',
        '#DB524B'
      ],
      hoverBackgroundColor: [
        '#58B957',
        '#F2AE43',
        '#DB524B'
      ],
    }]
  } ;
  hasChartData: boolean = false ;
  chartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (toolTip) => {
            //let totalStudents: number = toolTip.dataset['data'].reduce((prev: number, curr: number) => { return prev! + curr! }, 0) ;
            let totalStudents = 0 ;
            toolTip.dataset['data'].forEach((val: any) => totalStudents += (val as number)) ;

            return (totalStudents === 0) ? '' : `${Math.round((toolTip.parsed / totalStudents) * 100)}%` ;
          }
        }
      }
    },
  } ;
  reportIcon = faChartPie ;
  printIcon = faPrint ;
  infinityIcon = faInfinity ;
  loadingIcon = faSpinner ;
  diagnosticTitle = SubscriptionTypes.DiagnosticProduct ;
  systemTitle = SubscriptionTypes.FullProduct ;
  diagnosticLicenses: number = 0 ;
  systemLicenses: number = 0 ;
  tableData: any[] = [] ;
  sortColumn: number = -1 ;
  sortReverse: boolean = false ;
  message: string = '' ;
  messageTitle: string = '' ;
  messageStatus: string = '' ;
  successIcon = faCheckCircle ;
  errorIcon = faCircleExclamation ;
  studentScoreRange: string = '' ;
  processingDiag: boolean = false ;
  processingFull: boolean = false ;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective ;
  @ViewChild(StudentsFilterComponent) studentFilter?: StudentsFilterComponent ;
  @ViewChild('screenerSummaryCanvas') screenerSummaryCanvas!: ElementRef<HTMLCanvasElement> ;
  @ViewChild('screenerSummaryImg') screenerSummaryImg!: ElementRef<HTMLImageElement> ;
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement> ;

  constructor(
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private enrollmentService: EnrollmentService,
    private studentService: StudentsService,
    private reportService: ReportsService,
    private utilityService: UtilityService,
    private printService: PrintService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionStorageService.getUserData() ;
    this.isTeacher = (this.currentUser!.isTeacherUser()) ;
    this.showSchoolFilter = this.currentUser!.isSchoolUser() || this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() ;

    if (this.route.snapshot.data['resolveData'].erred)
    {
      this.schoolFilterError = this.route.snapshot.data['resolveData'].message ;
    }
    else
    {
      this.students = this.route.snapshot.data['resolveData'].students ;
      this.grades = this.route.snapshot.data['resolveData'].grades ;
      this.classes = this.route.snapshot.data['resolveData'].classes ;
      this.diagnosticLicenses = this.route.snapshot.data['resolveData'].diagnosticLicenses ;
      this.systemLicenses = this.route.snapshot.data['resolveData'].interventionLicenses ;
      this.filteredStudents = this.students ;
    }

    // Filter data
    this.districts = this.route.snapshot.data['filterData'].districts ;
    this.schools = this.route.snapshot.data['filterData'].schools ;
    this.teachers = this.route.snapshot.data['filterData'].teachers ;

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser())
    {
      // The FIL user and District user roles can select all schools
      this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true }) ;
    }

    if (this.currentUser!.isFILUser() || this.currentUser!.isDistrictUser() || this.currentUser!.isSchoolUser())
    {
      // The FIL user and District and School user roles can select all teachers
      this.teachers.unshift(ManagementUser.getGenericUser()) ;
    }

    this.filterReportData() ;
  }

  filterStudents(filterOpts: any) {
    // Reset our sort
    this.sortColumn = -1 ;
    this.sortReverse = false ;

    // Grade value that comes from the filter is our true, displayed grade (not our internal grade, so 4 is 4th grade)
    let gradeNum = parseInt(filterOpts.grade) ;
    let selectedGradeGroup = (filterOpts.grade === 'four_below_all' || (!isNaN(gradeNum) && gradeNum <= 4)) ? 'elementary' : 'secondary' ;
    if (filterOpts.grade === 'all')
    {
      this.tierLabels = {
        'proficient' : '',
        'some-risk' : '',
        'high-risk' : '',
      } ;
    }
    else
    {
      this.tierLabels = {
        'proficient' : ScoreCutoffs[selectedGradeGroup]['labels']['topTier']['range'],
        'some-risk' : ScoreCutoffs[selectedGradeGroup]['labels']['middleTier']['range'],
        'high-risk' : ScoreCutoffs[selectedGradeGroup]['labels']['bottomTier']['range'],
      } ;
    }

    this.filteredStudents = this.studentService.filterStudents(this.students, filterOpts) ;
    this.filterReportData() ;
    this.selectedStudents = new Set<number>() ;
  }

  filterSchoolTeacher() {
    let schoolID = this.reportService.getSelectedSchoolForReports().schoolID ;
    let teacherID = this.reportService.getSelectedTeacherForReports().userID ;

    this.reportService.getScreenerReportData(schoolID, teacherID).subscribe({
      next: (data: any) => {
        if (data.erred)
        {
          this.schoolFilterError = data.message;
        }
        else
        {
          this.schoolFilterError = '' ;
          this.students = data.students ;
          this.grades = data.grades ;
          this.classes = data.classes ;
          this.diagnosticLicenses = data.diagnosticLicenses ;
          this.systemLicenses = data.interventionLicenses ;
          this.filteredStudents = this.students ;

          this.filterReportData() ;
        }
      }
    }) ;
  }

  moveSelectedStudents(product: string) {
    // Sanity checks
    if (!this.studentsAreSelected || !this.isTeacher) return ;
    if (product === 'full' && this.systemLicenses !== -1 && this.selectedStudents.size > this.systemLicenses) return ;
    if (product === 'diagnostic' && this.diagnosticLicenses !== -1 || this.diagnosticLicenses !== -2 && this.selectedStudents.size > this.diagnosticLicenses) return ;

    if (product === 'full')
    {
      this.processingFull = true ;
      this.enrollmentService.assignFullProductSubscriptionToStudents(Array.from(this.selectedStudents)).pipe(
        switchMap((resp) => {
          return this.reportService.getScreenerReportData(this.currentUser!.schoolID, this.currentUser!.userID) ;
        }),
      ).subscribe({
        next: (results) => {
          this.systemLicenses = results.interventionLicenses ;
          this.students = results.students ;
          this.grades = results.grades ;
          this.classes = results.classes ;
          this.selectedStudents = new Set<number>() ;
          this.studentsAreSelected = false ;
          this.processingFull = false ;
          this.filterStudents(this.studentFilter?.getFilterSettings()) ;

          this.messageTitle = 'Assign System Subscription' ;
          this.message = 'The selected students were successfully assigned a System Subscription' ;
          this.messageStatus = 'success' ;
        },
        error: (err: any) => {
          this.processingFull = false ;
          this.messageTitle = 'Assign System Subscription' ;
          this.message = 'An error occurred while trying to assign the selected students to a System Subscription' ;
          this.messageStatus = 'error' ;

          Sentry.captureException(err, {
            tags: {
              section: 'reports',
              report: 'screener',
              action: 'assign-full-subscription',
              students: Array.from(this.selectedStudents).join(','),
              availSystemLicenses: this.systemLicenses,
            }
          }) ;
        }
      });
    }
    else if (product === 'diagnostic') {
      this.processingDiag = true ;
      this.enrollmentService.assignDiagnosticSubscriptionToStudents(Array.from(this.selectedStudents)).pipe(
        switchMap((resp) => {
          return this.reportService.getScreenerReportData(this.currentUser!.schoolID, this.currentUser!.userID) ;
        }),
      ).subscribe({
        next: (results) => {
          this.diagnosticLicenses = results.diagnosticLicenses ;
          this.students = results.students ;
          this.grades = results.grades ;
          this.classes = results.classes ;
          this.selectedStudents = new Set<number>() ;
          this.studentsAreSelected = false ;
          this.processingDiag = false ;
          this.filterStudents(this.studentFilter?.getFilterSettings()) ;

          this.messageTitle = 'Assign Diagnostic Subscription' ;
          this.message = 'The selected students were successfully assigned a Diagnostic Subscription' ;
          this.messageStatus = 'success' ;
        },
        error: (err: any) => {
          this.processingDiag = false ;
          this.messageTitle = 'Assign Diagnostic Subscription' ;
          this.message = 'An error occurred while trying to assign the selected students to a Diagnostic Subscription' ;
          this.messageStatus = 'error' ;

          Sentry.captureException(err, {
            tags: {
              section: 'reports',
              report: 'screener',
              action: 'assign-diagnostic-subscription',
              students: Array.from(this.selectedStudents).join(','),
              availDiagnosticLicenses: this.diagnosticLicenses,
            }
          }) ;
        }
      }) ;
    }
  }

  toggleAllStudents(e: any) {
    if (e.target.checked)
    {
      this.filteredStudents.forEach((student) => {
        if (student.firstAssessmentScore && student.firstAssessmentScore.isActive)
        {
          this.selectedStudents.add(student.userID) ;
        }
      }) ;
      this.studentsAreSelected = this.selectedStudents.size > 0 ;
    }
    else
    {
      this.selectedStudents = new Set<number>() ;
      this.studentsAreSelected = false ;
    }
  }

  studentToggled(e: any) {
    if (e.target.checked)
    {
      this.selectedStudents.add(parseInt(e.target.value)) ;
    }
    else
    {
      this.selectedStudents.delete(parseInt(e.target.value)) ;
    }
    this.studentsAreSelected = this.selectedStudents.size > 0 ;
  }

  sortTable(colIdx: number) {
    if (this.sortColumn === colIdx)
    {
      this.sortReverse = !this.sortReverse ;
    }
    this.sortColumn = colIdx ;

    this.tableData = (this.sortReverse) ? _.sortBy(this.tableData, this.sortFunction, this).reverse() : _.sortBy(this.tableData, this.sortFunction, this) ;
  }

  printSummary() {
    let screenerSummaryCanvas = this.screenerSummaryCanvas.nativeElement;
    let screenerSummaryImg = this.screenerSummaryImg.nativeElement;
    this.reportService.convertCanvasToImage(screenerSummaryCanvas, screenerSummaryImg);
    let school = this.reportService.getSelectedSchoolForReports().name;
    let teacher = this.reportService.getSelectedTeacherForReports();
    let teacherName = `${teacher.firstName} ${teacher.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, school, teacherName);
  }

  private filterReportData() {
    this.highRiskCnt = 0 ;
    this.someRiskCnt = 0 ;
    this.proficientCnt = 0 ;
    this.tableData = [] ;

    this.filteredStudents.forEach((student) => {
      // This is ported over from the legacy app, but this logic makes it appear that
      // we are iterating over all assessments, so if there was more than one sent back
      // we could be counting extra (more than 1 assessment per student) -- however, it
      // looks like when the call to `/screenerreports` is made, the query returns just
      // the Screener data, so a single assessment
      let studentGroup = (student.grade <= 5) ? 'elementary' : 'secondary' ;
      student.assessmentScores?.forEach((assessment) => {
        if (assessment && assessment.dateCompleted)
        {
          if (assessment.systemScore < ScoreCutoffs[studentGroup]['automaticity']['some-risk']) this.highRiskCnt++ ;
          else if (assessment.systemScore < ScoreCutoffs[studentGroup]['automaticity']['proficient']) this.someRiskCnt++ ;
          else this.proficientCnt++ ;
        }
      }) ;

      // Set our screener results class
      // FUTURE: Could this be moved to the assessment score model as a method?
      let screenerClass = '' ;
      if (student.firstAssessmentScore && student.firstAssessmentScore.dateCompleted)
      {
        if (student.firstAssessmentScore.systemScore < ScoreCutoffs[studentGroup]['automaticity']['some-risk'])
        {
          screenerClass = 'score-red' ;
        }
        else if (student.firstAssessmentScore.systemScore < ScoreCutoffs[studentGroup]['automaticity']['proficient'])
        {
          screenerClass = 'score-yellow' ;
        }
        else
        {
          screenerClass = 'score-green' ;
        }
      }

      if (student.firstAssessmentScore!.screenerResultsLow < 0 || student.firstAssessmentScore!.screenerResultsHigh < 0) {
        this.studentScoreRange = '0';
      } else {
        this.studentScoreRange = `${student.firstAssessmentScore!.screenerResultsLow} - ${student.firstAssessmentScore!.screenerResultsHigh}`;
      }

      // Push to our tableData array, which contains simplified (and thus sortable) data to display
      this.tableData.push([
        student.firstAssessmentScore?.isActive,
        `${student.firstName} ${student.lastName}`,
        this.utilityService.getStudentGrade(student.grade),
        this.studentScoreRange,
        (student.firstAssessmentScore && student.firstAssessmentScore.dateStarted) ? formatDate(student.firstAssessmentScore.dateStarted, 'MM/dd/YYYY', 'en-us') : '',
        (student.firstAssessmentScore && student.firstAssessmentScore.dateCompleted) ? formatDate(student.firstAssessmentScore.dateCompleted, 'MM/dd/YYYY', 'en-us') : '',
        screenerClass,
        student.userID
      ]) ;

      // Track how many students have not started their screener assessment
      if (student.firstAssessmentScore && student.firstAssessmentScore.isActive) this.studentsNotStarted++ ;
    }) ;

    this.chartData.datasets[0].data = [ this.proficientCnt, this.someRiskCnt, this.highRiskCnt ] ;

    this.hasChartData = this.chartData.datasets[0].data.reduce((a: number, b: number) => a + b, 0) > 0 ;

    this.chart?.update() ;
  }

  private sortFunction(val: any) {
    if (this.sortColumn === 3)
      // If we are sorting by Range (sortColumn 3), then first ensure that we are completed with the
      // screener (column 5). If so split our range and sort by our lower
      // If not, then sort by start date (column 4) if started
      // If not, just push it down to the bottom (very large negative number)
    {
      if (val[5]) return parseInt(val[this.sortColumn].split('-')[0].trim()) ;
      else if (val[4]) return ((new Date(val[4]).getTime()) - (new Date()).getTime()) ;
      else return -10000000000000 ;
    }
    else if (this.sortColumn === 4 || this.sortColumn === 5) // check if sorting by start date or completed date
    {
        let date = new Date(val[this.sortColumn]); //use a Date object to sort instead of sorting lexicographically
        return date.getTime();
    }
    return val[this.sortColumn] ;
  }
}
