import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPrint, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { School } from '../../core/models/school.model';
import { District } from '../../core/models/district.model';
import { Student } from '../../core/models/student.model';
import { SchoolClass } from '../../core/models/school-class.model';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { ManagementUser } from '../../core/models/management-user.model';
import { PrintService } from 'src/app/core/services/print.service';
import { ReportsService } from 'src/app/core/services/reports.service';
import { LoadingService } from '../../core/services/loading.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'wf-reports-students-list',
  templateUrl: './reports-students-list.component.html',
  styleUrls: ['./reports-students-list.component.css']
})
export class ReportsStudentsListComponent implements OnInit {
  @ViewChild('printContent') printContent!: ElementRef<HTMLElement>;

  managementUser: ManagementUser | null = null;
  isParentUser: boolean = false;
  userRole: string = '';

  students: Student[] = [];
  filteredStudents: Student[] = [];
  grades: string[] = [];
  classes: SchoolClass[] = [];
  schoolFilterError: string = '';

  showSchoolFilter: boolean = true;
  districts: District[] = [];
  schools: School[] = [];
  teachers: ManagementUser[] = [];

  searchTerm: string = '';

  printIcon = faPrint;
  studentIcon = faUserGraduate;
  public Math = Math;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportsService,
    private sessionService: SessionStorageService,
    private loadingService: LoadingService,
    private printService: PrintService
  ) {}

  ngOnInit(): void {
    this.managementUser = this.sessionService.getUserData();
    if (this.managementUser) {
      this.isParentUser = this.managementUser.isParentUser();
      this.userRole = this.managementUser.userRole;
    }

    const filterData = this.route.snapshot.data['filterData'];
    if (filterData) {
      this.districts = filterData.districts;
      this.schools = filterData.schools;
      this.teachers = filterData.teachers;
    }

    this.schools.unshift({ schoolID: 0, districtID: 0, name: 'All', enabled: true });
    this.teachers.unshift(ManagementUser.getGenericUser());

    this.updateStudentListFilter();
  }

  /**
   * Filters the list of students by first or last name based on the searchTerm.
   */
  searchStudents(): void {
    if (!this.students) {
      return;
    }

    const term = this.searchTerm.toLowerCase();
    if (!term) {
      this.filteredStudents = [...this.students];
    } else {
      this.filteredStudents = this.students.filter(student =>
        student.firstName.toLowerCase().includes(term) ||
        student.lastName.toLowerCase().includes(term)
      );
    }
  }

  /**
   * Updates the student list based on the selected school and teacher filter.
   * Fetches updated student data from the server and reapplies any active search.
   */
  updateStudentListFilter(): void {
    this.loadingService.start() ;
    this.reportService.getStudentListReportData().subscribe({
      next: (data: any) => {
        this.loadingService.finish() ;
        if (data.erred) {
          this.schoolFilterError = data.message;
          this.students = [];
          this.filteredStudents = [];
          this.grades = [];
          this.classes = [];
        } else {
          this.schoolFilterError = '';
          this.students = data || [];
          this.searchStudents();
        }
      },
      error: (err: any) => {
        this.loadingService.finish() ;
        console.error('Error fetching student list data:', err);
        this.schoolFilterError = 'An error occurred while fetching data.';

        Sentry.captureException(err, {
          tags: {
            section: 'reports',
            report: 'students-list',
            action: 'get-student-list-data',
          }
        }) ;
      }
    });
  }

  /**
   * Opens the print window to print the student list.
   */
  printStudents(): void {
    const userData = this.sessionService.getUserData();
    const teacher = `${userData?.firstName} ${userData?.lastName}`;
    this.printService.openPrintWindow(this.printContent.nativeElement, userData?.school || '', teacher);
  }
}
