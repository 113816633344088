export interface User {
  userID: number,
  userRole: string,
  firstName: string,
  middleName: string | null,
  lastName: string,
  username: string,
  password: string | null,
  enabled: boolean,
  externalIdentities: { [key: string] : string },
}

export interface ReportTeacher {
  userID: number,
  displayName: string,
}

export const UserRoles = {
  PARENT: 'ROLE_PARENT',
  TEACHER: 'ROLE_TEACHER',
  TEACHER_CURRICULUM: 'ROLE_TEACHER_CURRICULUM',
  TEACHER_REVIEW: 'ROLE_TEACHER_REVIEW',
  TEACHER_SCHOOL_REPORTS: 'ROLE_TEACHER_SCHOOL_REPORTS',
  SCHOOL_REPORTS: 'ROLE_SCHOOL_REPORTS',
  DISTRICT_REPORTS: 'ROLE_DISTRICT_REPORTS',
  CUSTOMER_SERVICE: 'ROLE_CUSTOMER_SERVICE',
  FOUNDATIONS_REPORTS: 'ROLE_FOUNDATIONS_REPORTS',
  SALES_REP: 'ROLE_SALES_REP',
}

export const UserRoleGroups = {
  PARENTS: [
    UserRoles.PARENT
  ],
  TEACHERS: [
    UserRoles.TEACHER,
    UserRoles.TEACHER_CURRICULUM,
    UserRoles.TEACHER_REVIEW
  ],
  TEACHERS_PARENTS: [
    UserRoles.PARENT,
    UserRoles.TEACHER,
    UserRoles.TEACHER_CURRICULUM,
    UserRoles.TEACHER_REVIEW
  ],
  FOUNDATIONS_REPORTS: [
    UserRoles.FOUNDATIONS_REPORTS
  ],
  NOT_CUSTOMER_SERVICE: [
    UserRoles.PARENT,
    UserRoles.TEACHER,
    UserRoles.TEACHER_CURRICULUM,
    UserRoles.TEACHER_REVIEW,
    UserRoles.SCHOOL_REPORTS,
    UserRoles.DISTRICT_REPORTS,
    UserRoles.FOUNDATIONS_REPORTS,
    UserRoles.SALES_REP
  ],
  NOT_PARENTS_NOT_CUSTOMER_SERVICE: [
    UserRoles.TEACHER,
    UserRoles.TEACHER_CURRICULUM,
    UserRoles.TEACHER_REVIEW,
    UserRoles.SCHOOL_REPORTS,
    UserRoles.DISTRICT_REPORTS,
    UserRoles.FOUNDATIONS_REPORTS,
    UserRoles.SALES_REP
  ],
  DISTRICT_REPORTS: [
    UserRoles.SCHOOL_REPORTS,
    UserRoles.DISTRICT_REPORTS,
    UserRoles.FOUNDATIONS_REPORTS,
    UserRoles.SALES_REP
  ],
  REPORTS: [
    UserRoles.TEACHER_SCHOOL_REPORTS,
    UserRoles.SCHOOL_REPORTS,
    UserRoles.DISTRICT_REPORTS,
    UserRoles.FOUNDATIONS_REPORTS,
    UserRoles.SALES_REP
  ],
  RESOURCES: [
    UserRoles.TEACHER,
    UserRoles.TEACHER_CURRICULUM,
    UserRoles.TEACHER_REVIEW,
    UserRoles.SCHOOL_REPORTS,
    UserRoles.DISTRICT_REPORTS,
    UserRoles.FOUNDATIONS_REPORTS,
    UserRoles.SALES_REP
  ],
  CUSTOMERS: [
    UserRoles.PARENT,
    UserRoles.TEACHER,
    UserRoles.TEACHER_CURRICULUM,
    UserRoles.TEACHER_REVIEW,
    UserRoles.SCHOOL_REPORTS,
    UserRoles.DISTRICT_REPORTS,
  ],
}

export const Genders = {
  FEMALE: 1,
  MALE: 2,
}
