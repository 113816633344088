import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { StudentsListComponent } from './students-list/students-list.component';
import { ReportsStudentsListComponent } from './reports-students-list/reports-students-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    StudentsListComponent,
    ReportsStudentsListComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        FontAwesomeModule,
        FormsModule,
    ]
})
export class StudentsModule { }
