<div>
  <div id="printContent" #printContent>
    <div class="print-hide" *ngIf="showSchoolFilter">
      <wf-school-teacher-filter
        [error]="schoolFilterError"
        [districts]="districts"
        [schools]="schools"
        [teachers]="teachers"
        [showDistrict]="managementUser!.isFILUser()"
        (filterChangeEvent)="updateStudentListFilter()">
      </wf-school-teacher-filter>
    </div>
    <div class="header-controls split-flex">
      <h3>
        <fa-icon [icon]="studentIcon" class="print-hide"></fa-icon> Student List
      </h3>
      <div class="print-hide">
        <button id="print-button" class="btn print-summary-button" (click)="printStudents()">
          <fa-icon [icon]="printIcon"></fa-icon> Print Student List
        </button>
      </div>
    </div>
    <div class="search-bar print-hide" style="margin: 15px 0;">
      <input
        type="text"
        class="form-control"
        placeholder="Search by name"
        [(ngModel)]="searchTerm"
        (input)="searchStudents()">
    </div>
    <div>
      <table class="report-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Grade</th>
            <th>Username</th>
            <th>Subscription Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let student of filteredStudents">
            <td>{{ student.firstName }}</td>
            <td>{{ student.lastName }}</td>
            <td>{{ student.grade | displayGrade }}</td>
            <!-- <td>{{ student.schoolClass || '' }}</td> -->
            <td>{{ student.username }}</td>
            <!-- <td>{{ student.password }}</td>
            <td>{{ student.totalSessionTime !== undefined ? Math.round(student.totalSessionTime / 3600) : '' }}</td> -->
            <td>
              {{ student.assessmentType }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
