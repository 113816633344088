import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';
import { Credentials } from '../models/credentials.model';

@Injectable({
  providedIn: 'root'
})
export class CleverService {

  constructor(
    private sessionStorage: SessionStorageService,
    private httpClient: HttpClient
  ) { }

  getUserInfo (bearerToken: string) {
    const reqOpts = {
      headers: {
        'Access-Control-Allow-Origin' : '*'
      }
    } ;

    return this.httpClient.post<Credentials>(environment.dataServicesURL + 'clever/info',
      { bearerToken: bearerToken }, reqOpts);
  }

  // This link account request is to be made
  // after a user is logged in with BasicAuth so
  // that the username and password are set in
  linkUser(token: string): Observable<any> {
    const reqOpts = {
      headers: {
        'Access-Control-Allow-Origin' : "*"
      },
      withCredentials: true,
    };

    // Make an authenticated request to the server using BasicAuth
    //  and passing the token in with the request body.
    return this.httpClient.post(environment.dataServicesURL + 'manage/clever/teacher/link',
      { token: token }, reqOpts);
  }

  createNewUser(token: string) {
    // Build request with correct headers, base64 encode username and password
    const reqOpts = {
        headers: {
          'Access-Control-Allow-Origin' : "*"
        }
    };

    // Make the request to the server
    return this.httpClient.post<Credentials>(environment.dataServicesURL + 'manage/clever/teacher/add',
      { bearerToken: token }, reqOpts);
  }

  getSections() {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Content-Type': 'application/json',
      }
    } ;

    return this.httpClient.get(environment.dataServicesURL + 'manage/clever/sections', reqOpts);
  }

  // TODO: figure out the sections type?
  sendSectionsToEnroll(sections: any) {
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : '*',
      }
    } ;

    return this.httpClient.post(environment.dataServicesURL + 'manage/clever/sections/add',
      { sectionData: sections }, reqOpts);
  }

  setCleverFreeScreenerLastLogin(contactEmail: string) {
    const reqOpts = {
      headers: {
        'Access-Control-Allow-Origin' : "*"
      },
      withCredentials: true,
    } ;

    return this.httpClient.post(environment.dataServicesURL + 'manage/clever/contact/last-login', { email: contactEmail }, reqOpts) ;
  }
}
