<div #printContent id="printContent">
  <div class="print-hide" *ngIf="showSchoolFilter">
    <wf-school-teacher-filter
      [error]="schoolFilterError"
      [districts]="districts"
      [schools]="schools"
      [teachers]="teachers"
      [showDistrict]="currentUser!.isFILUser()"
      (filterChangeEvent)="filterSchoolTeacher()">
    </wf-school-teacher-filter>
  </div>
  <div [ngClass]="{ 'disabled-report' : schoolFilterError }"></div>
  <div class="header-controls split-flex">
    <h3>
      <img src="/assets/images/iconReports.svg" /> Reports
    </h3>
    <div class="print-hide filter-options">
      <wf-students-filter
          [grades]="grades"
          [classes]="classes"
          [filterExtraSupport]="true"
          (filterChangeEvent)="filterSessions($event)">
      </wf-students-filter>
    </div>
  </div>
  <div class="district-report-header">
    <div class="district-report-header-group">
      <span class="report">Group Report: </span>
      <div class="print-hide">
        <input type="radio" value="usage" id="usage-group-report" [(ngModel)]="reportType" (change)="setReportType()" />
        <label for="usage-group-report" style="margin-left: 5px;">Usage and Progress</label>
      </div>
      <div class="print-hide">
        <input type="radio" value="engagement" id="engagement-group-report" [(ngModel)]="reportType" (change)="setReportType()" />
        <label for="engagement-group-report" style="margin-left: 5px;">Engagement</label>
      </div>
      <div class="print-show" style="text-transform: capitalize;">{{ reportTypeDisplay }}</div>
    </div>
  </div>
  <div>
    <div class="print-hide alert message alert-danger" id="export-error" *ngIf="reportError">
      <div class="icon">
        <fa-icon [icon]="errorIcon"></fa-icon>
      </div>
      <div>
        <div class="bold">Report Export Error</div>
        {{ reportError }}
      </div>
    </div>
    <div class="report-section-header split-flex">
      <h5 class="results-report-header">
        <fa-icon [classes]="['print-hide']" [icon]="summaryIcon"></fa-icon>
        {{ fullProduct }}: Group Summary
      </h5>
      <div>
        <input type="checkbox" id="show-teacher-column" [(ngModel)]="showTeacherColumn" />
        <label for="show-teacher-column" style="margin-left: 5px; margin-right: 10px;">Show Teacher Column</label>
        <refresh-button (click)="refreshTableContent()"></refresh-button>
        <button id="export-button" class="btn export-report-button print-hide" (click)="exportGroupSummary()" [disabled]="exportingReport">
          <fa-icon [icon]="excelIcon" *ngIf="!exportingReport"></fa-icon>
          <fa-icon [icon]="loadingIcon" [spin]="true" *ngIf="exportingReport"></fa-icon>
          Export Results
        </button>
        <button id="print-button" class="btn print-summary-button print-hide" (click)="printSummary()" *ngIf="!currentUser!.isTeacherReviewUser()">
          <fa-icon [icon]="printIcon"></fa-icon> Print Results
        </button>
      </div>
    </div>
  </div>
  <table class="report-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of selectedColumns">
          <th *ngIf="!(column['property'] === 'student.teacher' && !showTeacherColumn)" (click)="sortTable(column['property'])">
            <div class="sortable-report-table-header">
              <div>{{ column['title'] }}</div>
              <div [ngClass]="{ 'arrow-up': !sortReverse, 'arrow-down': sortReverse }"
                   [style.visibility]="(sortColumn !== column['property']) ? 'hidden' : 'visible'">
              </div>
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let session of filteredSessions">
        <ng-template [ngIf]="reportType === 'usage'" [ngIfElse]="engagementColumns">
          <td>{{ session.student.firstName }} {{ session.student.lastName }}</td>
          <td>{{ session.student.grade | displayGrade }}</td>
          <td *ngIf="showTeacherColumn">
            {{ session.student.teacher }}
          </td>
          <td>{{ formatDate(session.startDate) }}</td>
          <td>{{ formatDate(session.lastUsageDate) }}</td>
          <td>
            <div>{{ getUnitStatus(session) }}</div>
          </td>
          <td>{{ (session.currentLevel === 0) ? '&mdash;' : session.currentLevel }}</td>
          <td>{{ (session.avgWeeklyUsage > 0) ? (session.avgWeeklyUsage | number:'1.0-0') : "0" }}</td>
          <td>{{ getDisplayValue(session, (session.prevUsageRangeTotalTime > 0) ? (session.prevUsageRangeTotalTime | number:'1.0-0') : "0") }}</td>
          <td>{{ getDisplayValue(session, (session.usageRangeTotalTime > 0) ? (session.usageRangeTotalTime | number:'1.0-0') : "0") }}</td>
          <td>{{ getDisplayValue(session, session.currentWeekCompletedTaskCounts) }}</td>
          <td>{{ getDisplayValue(session, session.previousWeekCompletedTaskCounts) }}</td>
        </ng-template>
        <ng-template #engagementColumns>
          <td>{{ session.student.firstName }} {{ session.student.lastName }}</td>
          <td>{{ session.student.grade | displayGrade }}</td>
          <td>{{ session.student.currentTeam }}</td>
          <td>{{ (session.totalPoints > 0) ? (session.totalPoints | number:'1.0-0') : "0" }}</td>
          <td>{{ (session.previousWeeksPoints > 0) ? (session.previousWeeksPoints | number:'1.0-0') : "0" }}</td>
          <td>{{ session.student.avatarsEarned }}</td>
          <td>{{ session.currentWeekLevelsCompleted }}</td>
          <td>{{ session.previousWeekLevelsCompleted }}</td>
          <td>
            <fa-icon class="fa-lg print-icon"
                     [ngClass]="{ 'green-color': session.previousWeekLevelsGoal, 'light-grey': !session.previousWeekLevelsGoal }"
                     [icon]="session.previousWeekLevelsGoal ? goalMet : goalNotMet">
            </fa-icon>
          </td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>
